// 引入vant UI组件
import Bridge from "@/utils/bridge";
import Vant, { Dialog, Toast } from "vant";
import "vant/lib/index.css";
import Vue from "vue";
import App from "./App.vue";
// 公共样式
import "./assets/styles/base.scss";
import i18n from "./i18n";
import mixin from "./mixin";
import router from "./router";
import store from "./store";
// 设置rem
// import "./utils/rem";

Vue.use(Vant);
Vue.mixin(mixin);
Vue.config.productionTip = false;

// 挂载到Vue原型链上
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$bridge = Bridge;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
