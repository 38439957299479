import Storage from "@/utils/storage";
import { v1 as uuidv1 } from "uuid";

let language = Storage.getItem("language");
let uuid = Storage.getItem("uuid");
// 是否有国际化，如果没有设置默认值
if (!language) {
  language = "zh_HK";
  Storage.setItem("language", language);
}
// 是否有uuid，如果没有设置默认值
if (!uuid) {
  uuid = uuidv1();
  Storage.setItem("uuid", uuid);
}

const state = () => ({
  language: language,
  uuid: uuid,
});

const getters = {};

const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
};

const actions = {
  // 设置语言
  setLanguage({ commit }, language) {
    Storage.setItem("language", language);
    commit("SET_LANGUAGE", language);
  },
  deleteUuid({ commit }) {
    Storage.removeItem("uuid");
    commit("SET_UUID", "");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
