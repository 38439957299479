import Storage from "@/utils/storage";

const state = () => ({
  token: Storage.getItem("token") || "",
});

const getters = {};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
};

const actions = {
  // 设置token
  setToken({ commit }, token) {
    Storage.setItem("token", token);
    commit("SET_TOKEN", token);
  },
  deleteToken({ commit }) {
    Storage.removeItem("token");
    commit("SET_TOKEN", "");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
