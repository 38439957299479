import { clientBrowser } from "@/utils/Foundation";
import { generateTitle } from "@/utils/i18n";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      // 设备
      client: this.clientBrowser(),
    };
  },
  methods: {
    ...mapActions({
      setToken: "user/setToken",
      setLanguage: "app/setLanguage",
    }),
    generateTitle,
    clientBrowser,
    // 切换语言和title
    switchLang(type) {
      this.$i18n.locale = type;
      this.setLanguage(type);
    },
    // 获取token
    handleToken() {
      return new Promise((resolve) => {
        const { client } = this;
        if (client == "ios") {
          this.$bridge.callhandler("getToken", {}, (token) => {
            resolve(token);
          });
        } else if (client == "android") {
          if (window.kkai) {
            let token = window.kkai.getToken();
            resolve(token);
          }
        }
      });
    },
    // 获取语言
    handleLanguageEnv() {
      return new Promise((resolve) => {
        const { client } = this;
        if (client == "ios") {
          this.$bridge.callhandler("getLanguageEnv", {}, (language) => {
            resolve(language);
          });
        } else if (client == "android") {
          if (window.kkai) {
            let language = window.kkai.getLanguageEnv();
            resolve(language);
          }
        }
      });
    },
    /**
     * 选择地址调用客户端方法（调用客户端设置默认地址）
     * @param {*} addressId 收货地址id
     * @param {*} receiveName 收货人姓名
     * @param {*} receivePhone 收货人联系方式
     * @param {*} receiveAddress 收货地址
     */
    handleSelectedAddr(addressId, receiveName, receivePhone, receiveAddress) {
      const { client } = this;
      if (client == "ios") {
        let params = {
          addressId,
          receiveName,
          receivePhone,
          receiveAddress,
        };
        this.$bridge.callhandler("updateShippingAddress", params);
      } else if (client == "android") {
        if (window.kkai) {
          window.kkai.updateShippingAddress(
            addressId,
            receiveName,
            receivePhone,
            receiveAddress
          );
        }
      }
    },

    /*
     *@描述: 跳转小秘书聊天
     *@参数: 无
     *@作者: 金涛
     *@日期: 2022-01-04 11:44:38
     */
    gotoIM() {
      const { client } = this;
      if (client == "ios") {
        this.$bridge.callhandler("gotoChatKKTeacher");
      } else if (client == "android") {
        if (window.kkai) {
          window.kkai.gotoChatKKTeacher();
        }
      }
    },

    /*
     *@描述: 调用客户端设置kk公告板查看更多页面标题方法
     *@参数: 无
     *@作者: 金涛
     *@日期: 2022-02-09 15:26:17
     */
    getKKtitle() {
      return new Promise((resolve) => {
        const { client } = this;
        if (client == "ios") {
          this.$bridge.callhandler("getNativeTitle", {}, (title) => {
            resolve(title);
          });
        } else if (client == "android") {
          if (window.kkai) {
            let title = window.kkai.getNativeTitle();
            resolve(title);
          }
        }
      });
    },

    /*
     *@描述: H5点击跳转原生升级会员页面
     *@参数: 跳转路径fullClassName
     *@作者: 金涛
     *@日期: 2022-02-17 19:22:38
     */
    goToVipBuyActivity(fullClassName) {
      const { client } = this;
      if (client == "ios") {
        this.$bridge.callhandler("JumpToPage", fullClassName);
      } else if (client == "android") {
        if (window.kkai) {
          window.kkai.JumpToPage(fullClassName);
        }
      }
    },

    // 隐藏客户端顶部菜单
    handleHideBarView() {
      const { client } = this;
      if (client === "ios") {
        this.$bridge.callhandler("hideActionBarView");
      } else if (client === "android") {
        if (window.kkai) {
          window.kkai.hideActionBarView();
        }
      }
    },

    // 显示客户端顶部菜单
    handleShowBarView() {
      const { client } = this;
      if (client === "ios") {
        this.$bridge.callhandler("showActionBarView");
      } else if (client === "android") {
        if (window.kkai) {
          window.kkai.showActionBarView();
        }
      }
    },
  },
};
