import Vue from "vue";
import VueI18n from "vue-i18n";
import Storage from "@/utils/storage";

Vue.use(VueI18n);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const messages = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const i18n = new VueI18n({
  locale: Storage.getItem("language") || "zh_HK",
  messages,
});

export default i18n;
