const ZH_HK = {
  route: {
    home: "首頁",
    privacy: "隱私協定",
    service: "服務協定",
    essenceMustKnow: "精華必知",
    drawNineSquares: "如何把我的家劃分為九格",
    addHomeOffice: "新增家宅/辦公室",
    annotation: "家宅/辦公室風水解析",
    addHomeOfficeResult: "提交完成",
    downloadApp: "app下載",
    shoppingAddress: "我的收貨地址",
    orderDetails: "訂單詳情",
    myProfile: "我的人譜",
    ignore: "不可忽視的2022年",
    bulletinBoardDetail: "公告详情",
    myMember: "我的會員",
    characterList: "人譜列表",
    characterDetail: "人譜詳情",
    shoppingHome: "線上商店",
    shoppingOrderList: "我的訂單",
  },
  unfold: "展開",
  putAway: "收起",
  loadingText: "加載中",
  noMore: "-沒有更多了-",
  requestFailed: "請求失敗，點擊重新加載",
  all: "全部",
  east: "東",
  south: "南",
  weat: "西",
  north: "北",
  nakamiya: "中宮",
  submitSuccess: "提交成功",
  officeResultLabel: "我們會細心檢查您所提交的資料內容並在3個工作日內完成分析",
  category: "類別",
  home: "家居",
  office: "辦公室",
  nationalProvincesCities: "國家省市區",
  streetBuildingName: "街道或大廈名",
  houseNumberFU: "大廈/門牌號碼/樓層/單位",
  uploadFloorPlan: "上傳平面圖",
  submit: "提交",
  selectRegion: "請選擇地區",
  pleaseChoose: "請選擇",
  uploading: "上傳中...",
  uploadedSuccess: "上傳成功",
  uploadFailed: "上傳失敗",
  uploadCompleted: "上傳完成",
  selectCountryProvinceCity: "請選擇國家省市區",
  enterStreetName: "請輸入街道或大廈名",
  enterBuildingHouseFloorUnit: "請輸入大廈/門牌號碼/樓層/單位",
  pleaseUploadFloorPlan: "請上傳平面圖",
  drawNineSquaresTips1: "1. 把家宅或辦公室分為 9 格（如上圖示）;",
  drawNineSquaresTips2: "2. 站在大門面向屋內;",
  drawNineSquaresTips3: "3. 按指南針找出【中宮、東、南、西、北】方位。",
  downloadH5Tips1: "運勢宜忌，一APP在手，運籌帷幄",
  downloadH5Tips2: "八字排盤",
  downloadH5Tips3: "風水解析",
  downloadH5Tips4: "專屬人譜",
  downloadImmediately: "立即下載",
  analysisReport: "分析報告",
  ninePacePlan: "九宮格平面圖",
  fengShuiNinePalaces: "風水九宮圖",
  fengShuiTips1: "新加放",
  fengShuiTips2: "已擺放",
  fengShuiTips3: "需拿走",
  addShippingAddress: "添加收貨地址",
  delete: "刪除",
  deleteAddress: "刪除地址",
  confirmDeleteAddress: "確認要刪除收貨地址嗎？",
  receiver: "收貨人",
  inputReceiver: "請填寫收貨人姓名",
  mobile: "手機號碼",
  inputMobile: "請填寫收貨人聯繫電話",
  area: "所在地區",
  address: "詳細地址",
  inputAddress: "请填写收货详细地址",
  sure: "确定",
  empty: "空空如也",
  emptyAddress: "暫無收貨地址，快去添加吧",
  emptyOrder: "暫時沒有訂單",
  pleaseSelectRegion: "請選擇完整的地區",
  chosen: "已選擇",
  defaultAddressnotDeleted: "默認地址不能被刪除",
  upTo10: "地址最多添加10條",
  orderNumber: "訂單編號",
  orderDate: "訂單日期",
  orderAmount: "訂單金額",
  contactMethod: "聯絡方式",
  receivers: "收 貨 人",
  shippingAddress: "收貨地址",
  orderContent: "訂單內容",
  detailed: "詳細",
  quantity: "數量",
  unitPrice: "單價",
  lumpSum: "總金額：HK ${n}",
  paymentProfile: "付款資料",
  paymentDate: "付款日期",
  paymentMethod: "付款方式",
  referenceNumber: "參考編號",
  contactLittleSecretary: "訂單有問題,請按此聯絡小秘書",
  freight: "運費",
  freeShipping: "免運費",
  shippingInformation: "發貨資料",
  deliveryDate: "發貨日期",
  shippingCompany: "運送公司",
  postalCode: "快遞編號",
  humanSpectrum: "人譜特性",
  humanSpectrumScore: "人譜分數",
  whatGenealogy: "什麼是人譜？",
  suitableColor: "宜用顏色",
  guessYouKnow: "猜你想知",
  otherCharacter: "其他人譜",
  detailedWish: "星運詳解",
  humanSpectrumTip: `
  以前的人一直都沿用我們祖先留下來的智慧，例如古代的科學家他們竟然可以發現月亮跟地球的距離而創造了一系列的科學數據；例如五行、再由五行衍生八字、易經、甚至風水等等，這都是我們中國古代科學的高智慧成果。\n
  但在現今的世界，很多人都認為這些古老的東西已經追不上潮流，所以西方首先用星座來告訴大家的算命新方向。若在今天仍然只用八字來算命、或是只靠風水來化解厄運，這個實在已經不足夠。起碼我們自己都會覺得有不足之處。\n
  現在的人類，思想已經不再單純，所以任何的計算命運方法，只要有絲毫不準確，那怕是90%算準了，只有10%的偏差，他們都抱有懷疑態度。因為一般人都認為要有這樣的態度才算是聰明的現代人。其實這想法沒有對或錯，因為整個宇宙的確改變了很多。\n
  簡單舉個例子，以前古人的速度，就是以我們辦事手腳快慢作準則，甚至是量度其人的思想靈活變通的反應；比較虛無一點的可指眼光遠大，這些都可以概括泛指的速度指標。但是現今這世界的速度是指光速、光纖、光頻、射頻、人造衛星等等；莫說是一個普通人，就連一個科學家、數學家也沒辦法簡單舉例一些實在的計算方法。可能有條方程式給他們說出來，但這個方程式在100個人甚至1千人裏面，可能沒有1人能真真正正知道是怎樣得出來的？\n
  因此如果你是一個喜歡術數的人，不論在什麼時代，由遠古的星辰論、到現在的地球論、再進化到宇宙星體論，不斷的鑽研及推敲，其實都是為了用一些普通人能夠掌握的知識，去幫助人類趨吉避凶而巳。\n\n
  在這過去幾十年來，有一些身心靈的學說，地球由三的維度正向上揚升，向着五維度的目標前行。這個說法沒人駁斥，或者真正擁有深厚科學知識的人也不能出來判斷這種現象是否存在；因為在我們這個世代，實在有很多種現象都沒法確確切切把證據放在枱上給人們認證。因為大家都認為這個宇宙的秘密實在太大太多，每一種說法都不可能確切地推翻。\n
  如果我們用這些星體算出來人們的命運，能見一定的準成度，那就是用另一邊的事實來作認證。\n
  可能我已經把這話題越撐越遠，不過肯定的是，墨提斯星與仙女星都只是一個名詞的代表，確實是以你的生日算出來的星體再推算出來你的命運是不是準確？這個才是重點。
  `,
  detailedWishTip:
    "太陽 - 代表事業，女性的另一半；<br>月亮 - 代表錢財，男性的另一半；<br>宇宙 - 代表長輩或貴人；<br>海洋 - 代表朋友或劫財；<br>火山 - 代表智慧或傷害；",
  constructionYearX: "建築年份：{n}年",
  releaseDate: "發布日期",
  memberShipUntil: "會員有效期至{n}",
  rechargeRecord: "充值記錄",
  vipEndTime: "會員到期時間：",
  vipStartTime: "會員開通時間：",
  vipTips: "暫無會員充值記錄，快去升級您的會員等級吧",
  cancelRenewal: "取消續費",
  upgradeMembership: "升級會員",
  ordinaryMember: "普通會員",
  goldMember: "金會員",
  diamondMembership: "鑽石會員",
  monthX: "{n}個月",
  iconAnnotation: "圖標註解",
  iconAnnotationTitles: [
    "流水盤-盛水的器血",
    "6條黑魚 — 黑色小魚",
    "流水盆和黑魚（二選一）或 兩者一齊擺放",
    "銅條板 — 有水晶及亞加力膠片兩款物料製造",
    "響鐘 — 發聲金屬鐘",
    "8粒原石 — 將8粒原石頭堆在一團擺放",
    "4支水種竹 — 富貴竹又名觀音竹",
    "紅地毯 — 紅色地毯",
  ],
  iconAnnotationContents: [
    "容量最少能盛3公升的水，水要流動，任何材質物料均可選用；顏色常以金、銀、藍、咖啡色，但多不選用紅色。",
    "或任何黑色的魚，只要整條魚是黑色便可選用；每次需要保持6條在魚缸內，如果黑魚擺放後其中有魚死去，則要補回活魚，要維持6條黑魚數目。",
    "代表這位置最佳是能擺放6條黑魚，但若因環境不許可，也可以用流水盤代替黑魚，甚至可以黑魚及流水盤一齊擺放，能達致最佳化煞效果。",
    "用6條表面經過電鍍的金屬條鑲嵌在內而成的銅條板塊。",
    "最佳款色為每隔15或30分鐘發聲一次，聲音指定為西敏寺的響聲。",
    "也可以用碗或碟把石頭盛載其中。大小尺碼勻可，如選用純色原石，以純白色為佳。若選用雜色也可；但不建議選用純黑色原石。（原石頭是指未經粉碎的原石頭；例如很多用作花園的鵝卵石頭，都曾經由粉碎為石粉，而再按鵝卵形狀製成石頭。這些都不屬於原石頭。)",
    "一定選用水養的品種，不可選泥種竹。數量為4支，長短皆可；若過高過長，可從根部修剪至合適高度，一般根部會於兩個月內重新長出。",
    "一般30cm x 40cm 大小尺碼，可以選用紅色、橙色或紫色為主色的地氈，任何物料皆可。 （於風水上紅色代表火，而有些凶星方位遇上紅色會加強其破壞力，因此紅地氈一定要小心擺放；而尺碼更不宜過大，恐會出現反效果。）",
  ],
  precautions: "注意事項",
  returnPre: "返回",
  preview: "預覽",
  fengShuiGoods: "風水物",
  addCart: "加入購物車",
  cancel: "取消",
  buy: "確認加入",
  cart: "購物車",
  checkout: "前往結賬",
  totalAmount: "總計",
  orderStatusAll: "全部",
  orderStatusDoing: "進行中",
  orderStatusDone: "已完成/取消",
  confirm: "確認",
  payFail: "取消支付  / 支付失敗",
  paySuccess: "支付成功",
  psySuccessMessage: "请在我的帳號>我的訂單查看订单。",
  soldOut: "缺貨",
};

export default ZH_HK;
