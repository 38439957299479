const EN_US = {
  route: {
    home: "Home",
    privacy: "Privacy Agreement",
    service: "Service Agreement",
    essenceMustKnow: "Essential Must Know",
    drawNineSquares: "How to Divide My Home into Nine Squares",
    addHomeOffice: "Add Home/Office",
    annotation: "Home/Office Feng Shui Analysis",
    addHomeOfficeResult: "Submission Completed",
    downloadApp: "Download App",
    shoppingAddress: "My Shipping Address",
    orderDetails: "Order Details",
    myProfile: "My Profile",
    ignore: "Unignorable Year 2022",
    bulletinBoardDetail: "Bulletin Board Details",
    myMember: "My Membership",
    characterList: "Character List",
    characterDetail: "Character Detail",
    shoppingHome: "Online Shopping",
    shoppingOrderList: "My Order",
  },
  unfold: "Expand",
  putAway: "Collapse",
  loadingText: "Loading",
  noMore: "- No more -",
  requestFailed: "Request failed, click to reload",
  all: "All",
  east: "East",
  south: "South",
  weat: "West",
  north: "North",
  nakamiya: "Center Palace",
  submitSuccess: "Submission Successful",
  officeResultLabel:
    "We will carefully review the information you have submitted and complete the analysis within 3 working days",
  category: "Category",
  home: "Home",
  office: "Office",
  nationalProvincesCities: "National Provinces and Cities",
  streetBuildingName: "Street or Building Name",
  houseNumberFU: "Building/Unit/House Number/Floor",
  uploadFloorPlan: "Upload Floor Plan",
  submit: "Submit",
  selectRegion: "Select Region",
  pleaseChoose: "Please Choose",
  uploading: "Uploading...",
  uploadedSuccess: "Uploaded Successfully",
  uploadFailed: "Upload Failed",
  uploadCompleted: "Upload Completed",
  selectCountryProvinceCity: "Select Country/Province/City",
  enterStreetName: "Enter Street or Building Name",
  enterBuildingHouseFloorUnit: "Enter Building/House/Floor/Unit",
  pleaseUploadFloorPlan: "Please Upload Floor Plan",
  drawNineSquaresTips1:
    "1. Divide your home or office into 9 squares (as shown in the figure above);",
  drawNineSquaresTips2: "2. Stand at the entrance facing the interior;",
  drawNineSquaresTips3:
    '3. Find the positions of "Center Palace", "East", "South", "West", and "North" using a compass.',
  downloadH5Tips1: "Fortune Tips, One App in Hand, Strategize",
  downloadH5Tips2: "Eight Characters Calculation",
  downloadH5Tips3: "Feng Shui Analysis",
  downloadH5Tips4: "Exclusive Profile",
  downloadImmediately: "Download Now",
  analysisReport: "Analysis Report",
  ninePacePlan: "Nine Square Plan",
  fengShuiNinePalaces: "Feng Shui Nine Palaces",
  fengShuiTips1: "Add",
  fengShuiTips2: "Placed",
  fengShuiTips3: "To be removed",
  addShippingAddress: "Add Shipping Address",
  delete: "Delete",
  deleteAddress: "Delete Address",
  confirmDeleteAddress: "Are you sure you want to delete the shipping address?",
  receiver: "Receiver",
  inputReceiver: "Please enter the recipient's name",
  mobile: "Mobile Number",
  inputMobile: "Please enter the recipient's contact number",
  area: "Area",
  address: "Address",
  inputAddress: "Please enter the detailed shipping address",
  sure: "Confirm",
  empty: "Empty",
  emptyOrder: "No shipping order",
  emptyAddress: "No shipping address, add one now",
  pleaseSelectRegion: "Please select a complete region",
  chosen: "Selected",
  defaultAddressnotDeleted: "Default address cannot be deleted",
  upTo10: "Up to 10 addresses can be added",
  orderNumber: "Order Number",
  orderDate: "Order Date",
  orderAmount: "Order Amount",
  contactMethod: "Contact Method",
  receivers: "Receiver",
  shippingAddress: "Shipping Address",
  orderContent: "Order Content",
  detailed: "Detailed",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lumpSum: "Total Amount: HK ${n}",
  paymentProfile: "Payment Information",
  paymentDate: "Payment Date",
  paymentMethod: "Payment Method",
  referenceNumber: "Reference Number",
  contactLittleSecretary:
    "If you have any questions about your order, please contact the secretary here",
  freight: "Freight",
  freeShipping: "Free Shipping",
  shippingInformation: "Shipping Information",
  deliveryDate: "Delivery Date",
  shippingCompany: "Shipping Company",
  postalCode: "Postal Code",
  humanSpectrum: "Human Spectrum",
  humanSpectrumScore: "Human Spectrum Score",
  whatGenealogy: "What is Human Spectrum?",
  suitableColor: "Suitable Colors",
  otherCharacter: "Other Human Spectrum",
  guessYouKnow: "Guess What You Want to Know",
  detailedWish: "Detailed Wish",
  humanSpectrumTip: `
  People of the past have always inherited the wisdom of our ancestors, such as ancient scientists who were able to discover the distance between the moon and the earth and create a series of scientific data; such as the Five Elements, then the Eight Characters derived from the Five Elements, the Classic of Changes (I Ching), and even Fengshui, etc., which are the high wisdom achievements of ancient Chinese science.\n

  But in the modern world, many people believe that these ancient things have not kept up with the trend, so the West first used constellations to tell people the new direction of fortune-telling. If we still only use eight characters to calculate fate or rely on feng shui to resolve misfortune today, this is indeed insufficient. At least we will feel inadequate.\n
  
  Nowadays, people's thinking is no longer simple, so any fortune-telling methods, as long as there is a slight inaccuracy, even if 90% of the accuracy, only 10% deviation, they all have a skeptical attitude. Because most people think that it takes such an attitude to be a smart modern person. In fact, this idea is neither right nor wrong, because the whole universe has changed a lot.\n
  
  As an example, the speed of the ancients was based on the speed of our own work; even the flexibility of our thoughts and the responsiveness to measure someone could be included. Besides, a more intangible and far-reaching point of view could also be included. These are all general indicators of speed. However, the speed of the world today refers to the speed of light, fiber optics, optical frequency, radio frequency, artificial satellites, etc.\n
  Even a scientist or a mathematician cannot easily give concrete examples of calculation. They may state an equation, but out of 100 or even 1000 people, nobody may know exactly how it was derived.\n
  
  Thus, if you are a person who loves astrology, no matter the era, from ancient celestial discourse to modern geocentric discourse to further evolution of cosmological bodies, continuous research and exploration, is actually to use some knowledge accessible to ordinary people, to help humanity avoid dangers and invite fortune.\n
  
  Over the past decades, some theories of spiritual health have been proposed, stating that the Earth is rising towards from three-dimensional and moving towards five-dimensional. Nobody has denied this, and even people with deep scientific knowledge can't judge whether such phenomenon exists or not. In this age, there are so many phenomena that cannot be verified by hard evidence. People believe that the secrets of the universe are so vast and numerous that no single theory can completely negate them.\n
  
  If we use these stars to calculate people's destinies, we can certainly expect a certain accuracy, that is, to authenticate with facts from the other side.\n
  
  I may have gone too far with this topic, but what's certain is that Metis and Andromeda are just names; is it really accurate to calculate your fate based on the stars in your birth chart? That's the real issue.`,
  detailedWishTip:
    "Sun - Represents career and the other half of women;<br>Moon - Represents wealth and the other half of men;<br>Universe - Represents elders or mentors;<br>Ocean - Represents friends or conflict energies;<br>Volcano - Represents wisdom or harm;",
  constructionYearX: "Year of Construction: {n} years",
  releaseDate: "Release Date",
  memberShipUntil: "Membership Valid Until {n}",
  rechargeRecord: "Recharge Record",
  vipEndTime: "Membership Expiration Time:",
  vipStartTime: "Membership Start Time:",
  vipTips:
    "No membership recharge records yet, upgrade your membership level now",
  cancelRenewal: "Cancel Renewal",
  upgradeMembership: "Upgrade Membership",
  ordinaryMember: "Ordinary Member",
  goldMember: "Gold Member",
  diamondMembership: "Diamond Membership",
  monthX: "{n} Months",
  iconAnnotation: "Icon Annotation",
  iconAnnotationTitles: [
    "Flowing Basin - A vessel filled with water",
    "6 Black Fish - Small black fish",
    "Flowing Basin and Black Fish (either one) or both together",
    "Copper Strips - Made of crystal and anti-glare film",
    "Resonant Clock - Metal clock that chimes",
    "8 Natural Stones - Stack 8 natural stones together",
    "4 Water Bamboo - Also known as Lucky Bamboo",
    "Red Carpet - Red-colored carpet",
  ],
  iconAnnotationContents: [
    "Able to hold a minimum of 3 liters of water. The water should be flowing, and any material can be used; colors are often gold, silver, blue, and brown, but red is usually not chosen.",
    "Or any black fish, as long as the whole fish is black; you need to keep 6 fish in the fish tank at all times. If any of the black fish die after placement, they must be replaced with live fish to maintain a total of 6 black fish.",
    "The ideal placement is to have 6 black fish, but if the environment does not allow it, a flowing basin can be used instead of black fish, or both the black fish and the flowing basin can be placed together to achieve optimal evil-warding effects.",
    "A copper strip made by embedding 6 metal strips that have undergone electroplating into it.",
    "The best model is one that chimes every 15 or 30 minutes. The sound should be the same as the Westminster chime.",
    "The stones can be placed in a bowl or plate. Any size is suitable, and pure-colored natural stones are preferred. If using multicolored stones, it is not recommended to use pure black stones. (Natural stones refer to uncrushed stones. For example, many garden stones are crushed into stone powder and then shaped into egg-shaped stones. These are not considered natural stones.)",
    "Choose water-grown varieties, not soil-grown. You need to use 4 water bamboo, and they can be of varying lengths. If they are too tall or long, you can trim them from the base to the appropriate height; usually, new shoots will grow from the base within two months.",
    "Typically, a 30cm x 40cm size is used. You can choose a red, orange, or purple-colored carpet made of any material. (In Feng Shui, red represents fire, and some ominous directions become stronger when red is present, so be careful when placing a red carpet; the size should not be too large, as it may have a reverse effect.)",
  ],
  precautions: "Precautions",
  returnPre: "Return",
  preview: "Preview",
  fengShuiGoods: "Feng Shui Products",
  addCart: "Add Cart",
  cancel: "Cancel",
  buy: "Buy",
  cart: "Cart",
  checkout: "Checkout",
  totalAmount: "Total",
  orderStatusAll: "All",
  orderStatusDoing: "In Progress",
  orderStatusDone: "Completed/Cancelled",
  confirm: "Confirm",
  payFail: "Cancel Payment / Payment Failed",
  paySuccess: "Payment Success",
  psySuccessMessage: "Please check your order at My Account > My Orders.",
  soldOut: "Sold out",
};

export default EN_US;
