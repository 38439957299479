<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      token: this.$store.getters.token,
      language: this.$store.getters.language,
    };
  },
  watch: {
    $route() {
      if (this.$route.query.token) {
        console.log(`token: ${this.$route.query.token}`);
        this.setToken(this.$route.query.token);
      }
      if (this.$route.query.language) {
        console.log(`language: ${this.$route.query.language}`);
        this.switchLang(this.$route.query.language);
      }
      // 针对用户端的家宅解析详情页面 or kk公告板详情判断此页面不设置网站title由后端数据下发title，其他页面不受限制
      if (this.$route.meta.title !== "annotation") {
        document.title = this.generateTitle(this.$route.meta.title);
      }
      if (this.$route.meta.title !== "detail") {
        document.title = this.generateTitle(this.$route.meta.title);
      }
      document.getElementById("app").scroll(0, 0);
      if (this.$route.query.fontSize) {
        localStorage.setItem("fontSize", this.$route.query.fontSize);
      }
      const fontSize = localStorage.getItem("fontSize");
      console.log(fontSize, "xxxx");
      if (fontSize) {
        document.documentElement.style.fontSize = fontSize;
      }
    },
  },
  created() {
    this.getAppInfo();
  },
  methods: {
    // 获取app信息
    getAppInfo() {
      // 获取国际化
      // this.handleLanguageEnv().then((language) => {
      //   this.switchLang(language);
      // });
    },
  },
};
</script>
