const ZH_CN = {
  route: {
    home: "首页",
    privacy: "隐私协议",
    service: "服务协议",
    essenceMustKnow: "精华必知",
    drawNineSquares: "如何把我的家划分为九格",
    addHomeOffice: "新增家宅/办公室",
    annotation: "家宅/办公室风水解析",
    addHomeOfficeResult: "提交完成",
    downloadApp: "app下载",
    shoppingAddress: "我的收货地址",
    orderDetails: "订单详情",
    myProfile: "我的人谱",
    ignore: "不可忽视的2022年",
    bulletinBoardDetail: "公告詳情",
    myMember: "我的会员",
    characterList: "人谱列表",
    characterDetail: "人谱详情",
    shoppingHome: "线上商店",
    shoppingOrderList: "我的订单",
  },
  unfold: "展开",
  putAway: "收起",
  loadingText: "加载中",
  noMore: "-没有更多了-",
  requestFailed: "请求失败，点击重新加载",
  all: "全部",
  east: "东",
  south: "南",
  weat: "西",
  north: "北",
  nakamiya: "中宫",
  submitSuccess: "提交成功",
  officeResultLabel: "我们会细心检查您所提交的资料内容并在3个工作日内完成分析",
  category: "类别",
  home: "家居",
  office: "办公室",
  nationalProvincesCities: "国家省市区",
  streetBuildingName: "街道或大厦名",
  houseNumberFU: "大厦/门牌号码/楼层/单位",
  uploadFloorPlan: "上传平面图",
  submit: "提交",
  selectRegion: "请选择地区",
  pleaseChoose: "请选择",
  uploading: "上传中...",
  uploadedSuccess: "上传成功",
  uploadFailed: "上传失败",
  uploadCompleted: "上传完成",
  selectCountryProvinceCity: "请选择国家省市区",
  enterStreetName: "请输入街道或大厦名",
  enterBuildingHouseFloorUnit: "请输入大厦/门牌号码/楼层/单位",
  pleaseUploadFloorPlan: "请上传平面图",
  drawNineSquaresTips1: "1. 把家宅或办公室分为 9 格（如上图示）;",
  drawNineSquaresTips2: "2. 站在大门面向屋内;",
  drawNineSquaresTips3: "3. 按指南针找出【中宫、东、南、西、北】方位。",
  downloadH5Tips1: "运势宜忌，一APP在手，运筹帷幄",
  downloadH5Tips2: "八字排盘",
  downloadH5Tips3: "风水解析",
  downloadH5Tips4: "专属人谱",
  downloadImmediately: "立即下载",
  analysisReport: "分析报告",
  ninePacePlan: "九宫格平面图",
  fengShuiNinePalaces: "风水九宫图",
  fengShuiTips1: "新加放",
  fengShuiTips2: "已摆放",
  fengShuiTips3: "需拿走",
  addShippingAddress: "添加收货地址",
  delete: "删除",
  deleteAddress: "删除地址",
  confirmDeleteAddress: "确认要删除收货地址吗？",
  receiver: "收货人",
  inputReceiver: "请填写收货人姓名",
  mobile: "手机号码",
  inputMobile: "请填写收货人联系电话",
  area: "所在地区",
  address: "详细地址",
  inputAddress: "请填写收货详细地址",
  sure: "確定",
  empty: "空空如也",
  emptyOrder: "暂时没有订单",
  emptyAddress: "暂无收货地址，快去添加吧",
  pleaseSelectRegion: "请选择完整的地区",
  chosen: "已选择",
  defaultAddressnotDeleted: "默认地址不能被删除",
  upTo10: "地址最多添加10条",
  orderNumber: "订单编号",
  orderDate: "订单日期",
  orderAmount: "订单金额",
  contactMethod: "联络方式",
  receivers: "收 货 人",
  shippingAddress: "收货地址",
  orderContent: "订单内容",
  detailed: "详细",
  quantity: "数量",
  unitPrice: "单价",
  lumpSum: "总金额：HK ${n}",
  paymentProfile: "付款资料",
  paymentDate: "付款日期",
  paymentMethod: "付款方式",
  referenceNumber: "参考编号",
  contactLittleSecretary: "订单有问题,请按此联络小秘书",
  freight: "运费",
  freeShipping: "免运费",
  shippingInformation: "发货资料",
  deliveryDate: "发货日期",
  shippingCompany: "运送公司",
  postalCode: "快递编号",
  humanSpectrum: "人谱特性",
  humanSpectrumScore: "人谱分数",
  whatGenealogy: "什么是人谱？",
  suitableColor: "宜用颜色",
  otherCharacter: "其他人谱",
  guessYouKnow: "猜你想知",
  detailedWish: "星运详解",
  humanSpectrumTip: `
  以前的人一直都沿用我们祖先留下来的智慧，例如古代的科学家他们竟然可以发现月亮跟地球的距离而创造了一系列的科学数据；例如五行、再由五行衍生八字、易经、甚至风水等等，这都是我们中国古代科学的高智慧成果。\n
  但在现今的世界，很多人都认为这些古老的东西已经追不上潮流，所以西方首先用星座来告诉大家的算命新方向。若在今天仍然只用八字来算命、或是只靠风水来化解厄运，这个实在已经不足够。起码我们自己都会觉得有不足之处。\n
  现在的人类，思想已经不再单纯，所以任何的计算命运方法，只要有丝毫不准确，那怕是90%算准了，只有10%的偏差，他们都抱有怀疑态度。因为一般人都认为要有这样的态度才算是聪明的现代人。其实这想法没有对或错，因为整个宇宙的确改变了很多。\n
  简单举个例子，以前古人的速度，就是以我们办事手脚快慢作准则，甚至是量度其人的思想灵活变通的反应；比较虚无一点的可指眼光远大，这些都可以概括泛指的速度指标。但是现今这世界的速度是指光速、光纤、光频、射频、人造卫星等等；莫说是一个普通人，就连一个科学家、数学家也没办法简单举例一些实在的计算方法。可能有条方程式给他们说出来，但这个方程式在100个人甚至1千人里面，可能没有1人能真真正正知道是怎样得出来的？\n
  因此如果你是一个喜欢术数的人，不论在什么时代，由远古的星辰论、到现在的地球论、再进化到宇宙星体论，不断的钻研及推敲，其实都是为了用一些普通人能够掌握的知识，去帮助人类趋吉避凶而巳。\n
  在这过去几十年来，有一些身心灵的学说，地球由三的维度正向上扬升，向着五维度的目标前行。这个说法没人驳斥，或者真正拥有深厚科学知识的人也不能出来判断这种现象是否存在；因为在我们这个世代，实在有很多种现象都没法确确切切把证据放在台上给人们认证。因为大家都认为这个宇宙的秘密实在太大太多，每一种说法都不可能确切地推翻。\n
  如果我们用这些星体算出来人们的命运，能见一定的准成度，那就是用另一边的事实来作认证。\n
  可能我已经把这话题越撑越远，不过肯定的是，墨提斯星与仙女星都只是一个名词的代表，确实是以你的生日算出来的星体再推算出来你的命运是不是准确？这个才是重点。
  `,
  detailedWishTip:
    "太阳 - 代表事业，女性的另一半；<br>月亮 - 代表钱财，男性的另一半；<br>宇宙 - 代表长辈或贵人；<br>海洋 - 代表朋友或劫财；<br>火山 - 代表智慧或伤害；",
  constructionYearX: "建築年份：{n}年",
  releaseDate: "发布日期",
  memberShipUntil: "会员有效期至{n}",
  rechargeRecord: "充值记录",
  vipEndTime: "会员到期时间：",
  vipStartTime: "会员开通时间：",
  vipTips: "暂无会员充值记录，快去升级您的会员等级吧",
  cancelRenewal: "取消续费",
  upgradeMembership: "升级会员",
  ordinaryMember: "普通会员",
  goldMember: "金会员",
  diamondMembership: "钻石会员",
  monthX: "{n}个月",
  iconAnnotation: "图标注解",
  iconAnnotationTitles: [
    "流水盘-盛水的器血",
    "6条黑鱼 — 黑色小鱼",
    "流水盆和黑鱼（二选一）或 两者一齐摆放",
    "铜条板 — 有水晶及亚加力胶片两款物料制造",
    "响钟 — 发声金属钟",
    "8粒原石 — 将8粒原石头堆在一团摆放",
    "4支水种竹 — 富贵竹又名观音竹",
    "红地毯 — 红色地毯",
  ],
  iconAnnotationContents: [
    "容量最少能盛3公升的水，水要流动，任何材质物料均可选用；颜色常以金、银、蓝、咖啡色，但多不选用红色。",
    "或任何黑色的鱼，只要整条鱼是黑色便可选用；每次需要保持6条在鱼缸内，如果黑鱼摆放后其中有鱼死去，则要补回活鱼，要维持6条黑鱼数目。",
    "代表这位置最佳是能摆放6条黑鱼，但若因环境不许可，也可以用流水盘代替黑鱼，甚至可以黑鱼及流水盘一齐摆放，能达致最佳化煞效果。",
    "用6条表面经过电镀的金属条镶嵌在内而成的铜条板块。",
    "最佳款色为每隔15或30分钟发声一次，声音指定为西敏寺的响声。",
    "也可以用碗或碟把石头盛载其中。大小尺码匀可，如选用纯色原石，以纯白色为佳。若选用杂色也可；但不建议选用纯黑色原石。 （原石头是指未经粉碎的原石头；例如很多用作花园的鹅卵石头，都曾经由粉碎为石粉，而再按鹅卵形状制成石头。这些都不属于原石头。)",
    "一定选用水养的品种，不可选泥种竹。数量为4支，长短皆可；若过高过长，可从根部修剪至合适高度，一般根部会于两个月内重新长出。",
    "一般30cm x 40cm 大小尺码，可以选用红色、橙色或紫色为主色的地毡，任何物料皆可。 （于风水上红色代表火，而有些凶星方位遇上红色会加强其破坏力，因此红地毡一定要小心摆放；而尺码更不宜过大，恐会出现反效果。）",
  ],
  precautions: "注意事项",
  returnPre: "返回",
  preview: "预览",
  fengShuiGoods: "风水物",
  addCart: "加入购物车",
  cancel: "取消",
  buy: "确认加入",
  cart: "购物车",
  checkout: "前往结账",
  totalAmount: "总计",
  orderStatusAll: "全部",
  orderStatusDoing: "进行中",
  orderStatusDone: "已完成/取消",
  confirm: "确认",
  payFail: "取消支付  / 支付失敗",
  paySuccess: "支付成功",
  psySuccessMessage: "请在我的帐号>我的订单查看订单。",
  soldOut: "缺货",
};

export default ZH_CN;
