import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    meta: {
      title: "home",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/home"),
  },
  // 家宅平面图
  {
    path: "/house",
    name: "house",
    meta: {
      title: "",
    },
    component: () => import("@/views/house"),
  },
  // 精华必知
  {
    path: "/essenceMustKnow",
    name: "essenceMustKnow",
    meta: {
      title: "essenceMustKnow",
    },
    component: () => import("@/views/essenceMustKnow"),
  },
  // 如何把我的家划分为九格
  {
    path: "/drawNineSquares",
    name: "drawNineSquares",
    meta: {
      title: "drawNineSquares",
    },
    component: () => import("@/views/drawNineSquares"),
  },

  // 新增家宅/办公室
  {
    path: "/addHomeOffice",
    name: "addHomeOffice",
    meta: {
      title: "addHomeOffice",
    },
    component: () => import("@/views/addHomeOffice"),
  },

  // 家宅详情
  {
    path: "/houseDetail",
    name: "houseDetail",
    meta: {
      title: "annotation",
    },
    component: () => import("@/views/houseDetail"),
  },

  // 家宅平面图上传结果页面
  {
    path: "/addHomeOffice/result",
    name: "result",
    meta: {
      title: "addHomeOfficeResult",
    },
    component: () => import("@/views/addHomeOffice/result"),
  },
  // 下载H5
  {
    path: "/downloadH5",
    name: "downloadH5",
    meta: {
      title: "downloadApp",
    },
    component: () => import("@/views/downloadH5"),
  },

  // 我的收货地址
  {
    path: "/shoppingAddress",
    name: "shoppingAddress",
    meta: {
      title: "shoppingAddress",
    },
    component: () => import("@/views/shoppingAddress"),
  },
  // 我的收货地址
  {
    path: "/shippingAddress",
    name: "shoppingAddress",
    meta: {
      title: "shoppingAddress",
    },
    component: () => import("@/views/shoppingAddress"),
  },

  // 订单详情
  {
    path: "/orderDetails",
    name: "orderDetails",
    meta: {
      title: "orderDetails",
    },
    component: () => import("@/views/orderDetails"),
  },

  // 我的人谱
  {
    path: "/myProfile",
    name: "myProfile",
    meta: {
      title: "myProfile",
    },
    component: () => import("@/views/myProfile"),
  },

  // 隐私协议和服务条款页面
  {
    path: "/agreement/privacy",
    name: "Privacy",
    meta: {
      title: "privacy",
    },
    component: () => import("@/views/agreement/privacy"),
  },

  // 活动，不可忽视的2022
  {
    path: "/activity/ignore",
    name: "Ignore",
    meta: {
      title: "ignore",
    },
    component: () => import("@/views/activity/ignore"),
  },

  // KK公告板详情
  {
    path: "/bulletinBoardDetail",
    name: "detail",
    meta: {
      title: "detail",
    },
    component: () => import("@/views/bulletinBoardDetail"),
  },

  // 我的会员
  {
    path: "/myMember",
    name: "myMember",
    meta: {
      title: "myMember",
    },
    component: () => import("@/views/myMember"),
  },
  // 隐私协议和服务条款页面
  {
    path: "/agreement/index",
    name: "agreement",
    meta: {
      title: "agreement",
    },
    component: () => import("@/views/agreement/index"),
  },
  {
    path: "/characterList",
    name: "characterList",
    meta: {
      title: "characterList",
    },
    component: () => import("@/views/characterList"),
  },
  {
    path: "/characterDetail/:id",
    name: "characterDetail",
    meta: {
      title: "characterDetail",
    },
    component: () => import("@/views/characterDetail"),
  },
  // 商城首页
  {
    path: "/shoppingHome",
    name: "shoppingHome",
    meta: {
      title: "shoppingHome",
    },
    component: () => import("@/views/shoppingHome"),
  },

  // 商品详情
  {
    path: "/shoppingGoodsDetail/:id",
    name: "shoppingGoodsDetail",
    meta: {
      title: "shoppingHome",
    },
    component: () => import("@/views/shoppingGoodsDetail"),
  },
  // 订单详情
  {
    path: "/shoppingOrderList",
    name: "shoppingOrderList",
    meta: {
      title: "shoppingOrderList",
    },
    component: () => import("@/views/shoppingOrderList"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
